/* Globals */

* {
    transition: 0.25s ease-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }
}

#logo {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotate {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
}

.custom-loader {
    width: 25px;
    height: 25px;
    display: inline-block;
    border-radius: 50%;
    background: radial-gradient(farthest-side,#766DF4 94%,#0000) top/8px 8px no-repeat, conic-gradient(#0000 30%,#766DF4);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:rotating 1s infinite linear;
}

#planet-mlp {
    z-index: 1;
    -webkit-animation: rotating 60s linear infinite;
    -moz-animation: rotating 60s linear infinite;
    -ms-animation: rotating 60s linear infinite;
    -o-animation: rotating 60s linear infinite;
    animation: rotating 60s linear infinite;
}

#stars-mlp {
    z-index: 0;
    top: -30%;
    -webkit-animation: rotating 20s linear infinite;
    -moz-animation: rotating 20s linear infinite;
    -ms-animation: rotating 20s linear infinite;
    -o-animation: rotating 20s linear infinite;
    animation: rotating 20s linear infinite;
}

/* Override I18n Select */
#rfs-btn {
    border: 2px solid rgb(255 255 255 / 0.1);
    background-color: rgb(255 255 255 / 0.05);
    border-radius: 1.5rem;
    padding: 0 7px 0 0;
    font-size: 16px !important;
}
#rfs-btn:after {
    border-top-color: rgb(255 255 255 / 0.2);
}
#rfs-btn[aria-expanded="true"]:after {
    border-bottom-color: rgb(255 255 255 / 0.2);
}

/* Fix issue with SweetAlert2 success */
.swal2-success-circular-line-left,
.swal2-success-circular-line-right,
.swal2-success-fix {
    background-color: transparent !important;
}

.circular-progress {
    position: absolute;
    top: -30px;
    left: -30px;
    --size: 250px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 10px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * pi * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);
    animation: progress-animation 10s linear 0s 1 forwards;
}

.circular-progress circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
}

.circular-progress circle.bg {
    stroke: transparent;
}

.circular-progress circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: #74bd00;
}

@property --progress {
    syntax: "<number>";
    inherits: false;
    initial-value: 0;
}

@keyframes progress-animation {
    from {
        --progress: 0;
    }
    to {
        --progress: 100;
    }
}